import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'

import {
} from '../../actions'

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 2,
  },
  rootTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    ...theme.mixins.gutters(),
    paddingBottom: '1rem',
  },
  rootContent: {
    paddingBottom: theme.spacing.unit,
    maxHeight: 500,
    overflowY: 'auto',
    ...theme.mixins.gutters(),
  },
  alertNew: {
    border: '3px solid ' + theme.palette.info.main,
    boxShadow: theme.shadows[5]
  },
  alert: {
    display: 'flex',
    flexGrow: 1,
    padding: '.5rem 1rem',
    borderRadius: 5,
    marginBottom: '1rem',
    position: 'relative',
    overflow: 'hidden'
  },
  alertBody: {
    flexGrow: 1
  },
  alertTitle: {
    fontSize: '1.25rem',
    fontWeight: 700,
    marginBottom: '.5rem'
  },
  alertIcon: {
    marginRight: '1rem',
    fontSize: '1.5rem',
    marginTop: '.125rem'
  },
  alertText: {
    fontSize: '1rem',
    '& p': {
      marginTop: 0,
      marginBottom: '.25rem'
    }
  },
  alertSuccess: {
    backgroundColor: theme.palette.success[50],
    color: theme.palette.success[300]
  },
  alertInfo: {
    backgroundColor: theme.palette.info[50],
    color: theme.palette.info[300]
  },
  alertError: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error[300],
  },
  date: {
    textAlign: 'right',
    fontSize: '.75rem',
    opacity: '.6'
  },
  alertNewBadge: {
    position: 'absolute',
    width: 100,
    height: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.info.main,
    color: 'white',
    right: -50,
    top: -20,
    transform: 'rotate(45deg)',
    transformOrigin: '0 0',
    fontWeight: 700
  }
})

class AlertGroup extends React.Component {
  render() {
    const { classes, item } = this.props

    const alertTypes = [
      {
        severity: 'Info',
        icon: 'message'
      },
      {
        severity: 'Error',
        icon: 'error_outline'
      },
      {
        severity: 'Success',
        icon: 'get_app'
      },
      {
        severity: 'Error',
        icon: 'build'
      }
    ]

    return (
      <Paper className={classes.root}>
        {item.title ? (
          <div className={classes.rootTitle}>
            {item.title}
          </div>
        ) : ''}
        <div className={classes.rootContent}>
          {item.items.map((alert, index) => {
            const alertType = alertTypes[alert.type] || alertTypes[0]

            return (
              <div key={index} className={[
                classes.alert,
                classes['alert' + alertType.severity],
                alert.isNew ? classes.alertNew : ''
                ].join(' ')}>
                {alert.isNew ? (
                  <div className={classes.alertNewBadge}>NEW</div>
                ) : ''}
                <div className={classes.alertIcon}>
                  <Icon className={classes.icon}>{alertType.icon}</Icon>
                </div>
                <div className={classes.alertBody}>
                  <div className={classes.alertTitle}>
                    {alert.title}
                  </div>
                  <div className={classes.alertText} dangerouslySetInnerHTML={{ __html: alert.text }}></div>
                  <div className={classes.date}>{alert.date}</div>
                </div>
              </div>
            )
          })}
        </div>
      </Paper>
    )
  }
}

AlertGroup.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AlertGroup)))
