import _ from 'lodash'

const initialState = {
  agendaFilters: null,
  agendaData: null,
  results: null,
  selectedEvent: null,
  errors: []
}

// Vars
let newState = null

const agendaReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'agenda_action_update_selected_event':
      newState = _.cloneDeep(state)

      newState.selectedEvent = action.data

      return newState
    case 'agenda_action_agenda_search':
      newState = _.cloneDeep(state)

      const previous = action.data.data.previous

      const currentEvents = previous ? 'pastEvents' : 'futureEvents'

      const otherEvents = previous ? 'futureEvents' : 'pastEvents'

      const oldResults = action.data.clear ? {
        futureEvents: [],
        pastEvents: [],
        hasMore: {
          futureEvents: true,
          pastEvents: true
        }
      } : _.cloneDeep(newState.results)

      newState.results = action.data.data

      newState.results[currentEvents] = newState.results.events.map((event, index) => {
        const toReturn = {
          ...event,
          __path: ['agendaData', 'events', index]

        }

        if (event.workers) {
          toReturn.workers = event.workers.map((worker, workerIndex) => {
            return {
              ...worker,
              __path: ['agendaData', 'events', index, 'workers', workerIndex]
            }
          })
        }

        return toReturn
      })

      const firstArray = previous ? newState.results[currentEvents] : oldResults[currentEvents]

      const secondArray = previous ? oldResults[currentEvents] : newState.results[currentEvents]

      newState.results[currentEvents] = firstArray.concat(secondArray)

      newState.results[otherEvents] = oldResults[otherEvents]

      newState.results.hasMore = oldResults.hasMore

      newState.results.hasMore[currentEvents] = action.data.data.events.length === 100

      newState.results.from = newState.results.from || oldResults.from

      newState.results.to = newState.results.to || oldResults.to

      return newState
    case 'agenda_action_agenda_filters_get':
      newState = _.cloneDeep(state)

      newState.agendaFilters = action.data.data

      return newState
    case 'agenda_action_agenda_data_get':
      newState = _.cloneDeep(state)

      newState.agendaData = action.data.data

      newState.loadingData = false

      if (action.data && typeof action.data.callback === 'function') {
        setTimeout((callback) => {
          callback()
        }, 100, action.data.callback)

        delete action.data.callback
      }

      newState.agendaData.events = newState.agendaData.events.map((event, index) => {
        const toReturn = {
          ...event,
          __path: ['agendaData', 'events', index]
        }

        if (event.workers) {
          toReturn.workers = event.workers.map((worker, workerIndex) => {
            return {
              ...worker,
              __path: ['agendaData', 'events', index, 'workers', workerIndex]
            }
          })
        }

        if (newState.previewedEvent && event.id === newState.previewedEvent.id) {
          newState.previewedEvent = event
        }

        return toReturn
      })

      return newState
    case 'agenda_action_update_item':
      newState = _.cloneDeep(state)

      const tmpDate = new Date()

      newState.agendaData.lastUpdate = tmpDate.getTime()

      const tmpProps = (action.props || [null])

      tmpProps.forEach(item => {
        const currentPath = item ? [...action.path, item.key] : action.path

        if (action.unset) {
          return _.unset(newState, currentPath)
        }

        _.set(newState, currentPath, item.value)
      })

      return newState
    case 'agenda_action_agenda_data_get_call':
      newState = _.cloneDeep(state)

      newState.loadingData = true

      newState.closeForm = false

      return newState
    case 'agenda_action_agenda_filters_get_call':
      newState = _.cloneDeep(state)

      newState.agendaFilters = null

      return newState
    case 'agenda_action_agenda_search_call':
      newState = _.cloneDeep(state)

      if (action.data.clear) {
        newState.results = null
      }

      return newState
    case 'agenda_action_agenda_save_post':
      newState = _.cloneDeep(state)

      newState.errors = []

      return newState
    case 'agenda_action_agenda_save_errors':
      newState = _.cloneDeep(state)

      newState.errors = Object.entries(action.data.details).map(([key, value]) => {
        if (!isNaN(key)) {
          return value
        }

        return null
      }).filter(item => !(!item))

      return newState
    case 'agenda_action_agenda_close_event':
      newState = _.cloneDeep(state)

      newState.closeForm = true

      return newState
    case 'agenda_action_agenda_set_active_event':
      newState = _.cloneDeep(state)

      newState.previewedEvent = action.data

      return newState
    default:
      return state
  }
}

export default agendaReducer
