import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TableAjaxComponent from '../../components/TableAjax'
import PageAjaxActions from '../../components/PageAjaxActions'
import _ from 'lodash'
import { getPaginationConfig } from '../../utils/pagination'
import Typography from '@material-ui/core/Typography'
import Icon from '../../components/Icon'

import {
  appActions,
  listPageAjaxActions,
  confirmActions,
  printActions
} from '../../actions'

const styles = (theme) => ({
  root: {
    margin: '1rem'
  },
  mBottom5: {
    marginBottom: '55px'
  },
  caption: {
    margin: '1rem',
  },
  captionIcon: {
    fontSize: 10,
    marginRight: 10,
    display: 'inline-block'
  }
})

class ListPageAjax extends React.Component {
  state = {
    selectedElements: {},
    loaded: false,
    params: {
      page: 1
    }
  }

  loadPage = () => {
    const {
      pageType,
      pageTypes,
      match,
      paginationConfig: propsPaginationConfig,
      lastPageType
    } = this.props

    let paginationConfig = getPaginationConfig(propsPaginationConfig)

    if (paginationConfig.lastViewedReset) {
      paginationConfig = {}
    }

    const { params } = match

    let isNewList = !lastPageType || lastPageType !== pageType

    if (!isNewList) {
      Object.entries(params).forEach(([key, value]) => {
        if (!paginationConfig || paginationConfig[key] !== value) {
          isNewList = true
        }
      })
    }

    const {
      listPageParams = {
        page: 1,
        ...params
      }
    } = !isNewList ? this.props : {}

    const paramsToUse = {
      ...listPageParams,
      ...params,
      ...paginationConfig,
      lastViewedReset: false
    }

    this.props.onListPageAjaxGet(pageType, paramsToUse)

    this.setState({
      params: {
        ...listPageParams,
        ...paginationConfig,
        lastViewedReset: false
      }
    }, () => {
      this.props.onListPagesAjaxGet(pageType, pageTypes, this.state.params, match)
    })
  }

  componentDidMount = () => {
    const {
      pageType,
      match,
    } = this.props

    const { params } = match

    this.props.onLoadPage(pageType, params, this.loadPage)
  }

  onChangeAction = (currentParams, newParams) => {
    const { pageType, pageTypes, listPageParams } = this.props

    this.setState({
      params: {
        ...currentParams,
        ...newParams
      }
    }, () => {
      this.props.onListPagesAjaxGet(pageType, pageTypes, {
        ...listPageParams,
        ...this.state.params
      }, false, false, true)
    })
  }

  onChange = (type, params) => {
    const { params: currentParams } = this.state

    switch (type) {
      case 'page':
        this.onChangeAction(currentParams, {
          ...params
        })

        break
      case 'rowsPerPage':
        this.onChangeAction(currentParams, {
          ...params,
          page: 1,
          sort: {}
        })

        break
      case 'sort':
        const { sort = {} } = currentParams

        this.onChangeAction(currentParams, {
          ...params,
          sort: {
            field: params.sort,
            direction: sort.field !== params.sort ? 'asc' : sort.direction === 'desc' ? 'asc' : 'desc'
          },
          page: 1
        })

        break
      default:
        const { filters = {} } = currentParams

        const currentFilterValue = filters[params.filter.field]

        const newValue = params.filter.value

        const oldValue = filters[params.filter.field]

        const newValueIsSet = !_.isNull(newValue) && !_.isUndefined(newValue) && newValue !== ''

        const oldValueIsSet = !_.isNull(oldValue) && !_.isUndefined(oldValue) && oldValue !== ''

        if (currentFilterValue !== newValue && (newValueIsSet || oldValueIsSet)) {
          delete filters[params.filter.field]

          if (newValueIsSet) {
            filters[params.filter.field] = newValue
          }

          delete params.filter

          delete params.filters

          this.onChangeAction(currentParams, {
            ...params,
            filters,
            page: 1
          })
        }

        break
    }
  }

  componentDidUpdate = (prevProps) => {
    const { loaded } = this.state

    const {
      listPageAjaxConfig,
      listPageAjaxReload,
      pageType,
      pageTypes,
      match,
      requestPagination = {}
    } = this.props

    const { params } = match

    const {
      listPageAjaxReload: prevListPageAjaxReload,
      requestPagination: prevRequestPagination = {},
      listPageAjaxConfig: prevListPageConfig
    } = prevProps

    const {
      titleBlock,
      listPagePageType
    } = (listPageAjaxConfig || {})

    if (!_.isEqual(listPageAjaxConfig, prevListPageConfig) && listPageAjaxConfig && (!loaded || listPagePageType !== pageType) && titleBlock) {
      this.setState({
        loaded: true
      }, () => {
        return this.props.onChangeTitleBlock(listPageAjaxConfig.titleBlock)
      })
    }

    if (!prevListPageAjaxReload && listPageAjaxReload) {
      this.props.onListPagesAjaxGet(pageType, pageTypes, this.state.params, true, false)
    }

    if (requestPagination.page !== prevRequestPagination.page && requestPagination.page < requestPagination.pageCount) {
      this.props.onListPagesAjaxGet(pageType, pageTypes, {
        ...params,
        page: requestPagination.page + 1
      }, true, false)
    }
  }

  onSelectElement = (selectedElements, selectAllPageElements = false) => {
    const newSelectedElements = {}

    Object.entries(selectedElements).forEach(([key, value]) => {
      if (value) {
        newSelectedElements[key] = value
      }
    })

    this.setState({
      selectedElements: newSelectedElements,
      selectAllPageElements
    }, () => {
      this.props.onUpdateSelectedElements(this.state.selectedElements, selectAllPageElements, this.props.pageType)
    })
  }

  onClickAction = (action, itemId, confirmed = false, item) => {
    const {
      pageType,
      pageTypes,
      match
    } = this.props

    if (action.confirm && !confirmed) {
      return this.props.onHandleOpen(action.confirm, this.onClickAction, [
        action,
        itemId,
        true
      ])
    }

    switch (action.action) {
      case 'clone':
        return this.props.onListPageAjaxClone(pageType, itemId)
      case 'delete':
        return this.props.onListPageAjaxDelete(pageType, pageTypes, itemId, match.params)
      case 'print':
        const params = {id: itemId}

        if (action.idName) {
          delete params.id

          params[action.idName] = itemId
        }

        if (action.paramsFromItem) {
          Object.entries(action.paramsFromItem).forEach(([key, value]) => {
            params[key] = item[value]
          })
        }

        return this.props.onPrintPost(action.url, params)
      default:
        return this.props.onListPageAjaxDefaultAction(action.action, pageType, itemId, match.params)
    }
  }

  render() {
    const {
      classes,
      listPageAjaxConfig,
      listPagesAjax,
      getFilteredItems,
      pageType,
      pageTypes,
      requestPagination,
      paginationConfig: propsPaginationConfig,
      listPageParams,
      lastPageType
    } = this.props

    const paginationConfig = _.cloneDeep(propsPaginationConfig[pageType]) || {}

    if (!listPageAjaxConfig || (lastPageType && pageType !== lastPageType)) {
      return ''
    }

    let { selectedElements, params } = this.state

    let { sort = {}, filters } = params

    if (!filters && lastPageType && lastPageType === pageType) {
      filters = listPageParams.filters
    }

    const { tableHead, tableHeadActions, labels, tableActions, caption } = listPageAjaxConfig

    const errorMessage = {
      message: 'Seleziona almeno un\'elemento',
      type: 'warning',
      autoHide: true
    }

    return (
      <div className={classes.mBottom5}>
        {listPageAjaxConfig.topActions ? (
          <PageAjaxActions actions={listPageAjaxConfig.topActions} isTop={true} actionsAsTab={listPageAjaxConfig.topActionsAsTab} selectedElements={_.cloneDeep(selectedElements)} {...{pageType, pageTypes}} fromAjaxPage={true} errorMessage={errorMessage} />
        ) : ''}
        {listPagesAjax ? (
          <Paper className={classes.root}>
            <TableAjaxComponent sort={sort} filters={filters} onChange={this.onChange} pageType={pageType} requestPagination={requestPagination} tableActions={tableActions} tableHead={tableHead} paginationConfig={paginationConfig} tableHeadActions={tableHeadActions} labels={labels} tableBody={listPagesAjax} setFilteredItems={getFilteredItems} onSelectElement={this.onSelectElement} onClickAction={this.onClickAction} activeBulk={listPageAjaxConfig.activeBulk} />
          </Paper>
        ) : null}
        {caption ? (
          <Typography className={classes.caption} variant="caption" gutterBottom>
            <Icon className={classes.captionIcon}>info-circle</Icon>
            <span dangerouslySetInnerHTML={{__html: caption}}/>
          </Typography>
        ) : null}
        {listPageAjaxConfig.bottomActions ? (
          <PageAjaxActions actions={listPageAjaxConfig.bottomActions} {...{pageType, pageTypes}} selectedElements={_.cloneDeep(selectedElements)} errorMessage={errorMessage} />
        ) : ''}
      </div>
    )
  }
}

ListPageAjax.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  ...state.appReducer,
  ...state.listPageAjaxReducer,
  ...state.confirmReducer,
  ...state.paginationConfigReducer
})

const mapDispatchToProps = dispatch => ({
  onLoadPage: (pageType, params, callback) => {
    dispatch(listPageAjaxActions.loadPage(pageType, params, callback))
  },
  onChangeTitleBlock: (titleBlock) => {
    dispatch(appActions.changeTitleBlock(titleBlock))
  },
  onListPageAjaxGet: (pageType, params) => {
    dispatch(listPageAjaxActions.listPageAjaxGet(pageType, params))
  },
  onUpdateSelectedElements: (selectedElements, selectAllPageElements, pageType) => {
    dispatch(listPageAjaxActions.updateSelectedElements(selectedElements, selectAllPageElements, pageType))
  },
  onListPageAjaxDefaultAction: (action, pageType, itemId, params) => {
    dispatch(listPageAjaxActions.listPageAjaxDefaultActionPost(action, pageType, itemId, params))
  },
  onListPageAjaxDelete: (pageType, pageTypes, itemId, params) => {
    dispatch(listPageAjaxActions.listPageAjaxDeletePost(pageType, pageTypes, itemId, params))
  },
  onListPageAjaxClone: (pageType, itemId) => {
    dispatch(listPageAjaxActions.listPageAjaxClone(pageType, itemId))
  },
  onListPagesAjaxGet: (pageType, pageTypes, params, append, activeLoading = true, update = false) => {
    dispatch(listPageAjaxActions.listPagesAjaxGet(pageType, pageTypes, params, true, append, activeLoading, update))
  },
  onHandleOpen: (config, callback, callbackParams) => {
    dispatch(confirmActions.handleOpen(config, callback, callbackParams))
  },
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ListPageAjax)))
