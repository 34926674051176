import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import MUIButton from '@material-ui/core/Button'
import Zoom from '@material-ui/core/Zoom'
import Icon from '../Icon'

import {
  printActions
} from '../../actions'

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: 0,
  },
  label: {
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 700,
    marginBottom: '.25rem'
  },
  button: {
    minWidth: 40,
    marginRight: 15,
    marginBottom: 0,
  },
  icon: {
    marginRight: 8
  }
})

class Buttons extends React.Component {
  render() {
    const { classes, field, onChange, readonly, appConfig, match } = this.props

    return (
      <div className={classes.root}>
        {field.label ? (
          <div className={classes.label}>{field.label}</div>
        ) : null}
        {field.buttons.map((button, index) => {
          const { action, actionType, updateRelated, saveBeforeAction, urlType, readonly: childReadonly } = button

          let actionProps = {
            onClick : () => {
              return onChange({type: 'string'}, {
                ...field,
                updateRelated
              }, '')
            }
          }

          if (action) {
            switch (actionType) {
              case 'navlink':
                actionProps = {
                  component: NavLink,
                  to: appConfig.appPrefix + action
                }
                break
              case 'download':
                actionProps = {
                  href: action,
                  target: '_blank',
                  rel: 'noopener noreferrer',
                  download: field.download
                }

                if (urlType === 'api') {
                  actionProps.href = appConfig.apiUrl + actionProps.href
                }

                break
              case 'print':
                actionProps = {
                  onClick : () => {
                    if (saveBeforeAction) {
                      return onChange({type: 'saveBeforeAction'}, {
                        callback: this.props.onPrintPost,
                        callbackProps: [action, {}],
                        params: match.params
                      }, '')
                    }

                    return this.props.onPrintPost(action, {})
                  }
                }
                break
              default:
            }
          }

          return (
            <Tooltip TransitionComponent={Zoom} title={button.tooltip || ''} key={index}>
              <MUIButton fullWidth={false} variant='contained' {...actionProps} color='primary' disabled={readonly || !(!childReadonly)} style={button.styles || {}} className={classes.button} >{button.icon ? (
                <Icon className={button.label ? classes.icon : ''}>{button.icon}</Icon>
              ) : null}{button.label || null}</MUIButton>
            </Tooltip>
          )
        })}
      </div>
    )
  }
}

Buttons.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Buttons)))
