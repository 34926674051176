import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Icon from '../../Icon'
import Zoom from '@material-ui/core/Zoom'
import Tooltip from '@material-ui/core/Tooltip'

import {

} from '../../../actions'

const styles = theme => ({
  disabled: {
    opacity: '.5'
  },
  button: {
    marginTop: -9
  },
  buttonIcon: {
    marginRight: '.5rem'
  }
})

class Submit extends React.Component {
  onClick = () => {
    const { field, onSubmit, index } = this.props

    switch (field.type) {
      case 'reset':
        return onSubmit(field.actionType, index, 'resetPage')
      default:
        return onSubmit(field.actionType, index)
    }
  }

  render() {
    const { field, enabled, classes } = this.props

    const { label, style, actionType, icon, readonly } = field

    const disabled = (actionType === 'selected' && !enabled ? true : false) || readonly

    const tooltip = disabled ? field.disabledTooltip || field.tooltip : field.tooltip

    return (
      <Tooltip TransitionComponent={Zoom} title={
        <span style={{ fontSize: '14px' }}>
          {tooltip || ''}
        </span>
      }>
        <div>
          <Button fullWidth variant='contained' style={style} className={classes.button + (disabled ? ' ' + classes.disabled : '')} disabled={disabled} onClick={this.onClick}>{icon ? (
            <Icon className={classes.buttonIcon}>{icon}</Icon>
          ) : ''}{label}
          </Button>
        </div>
      </Tooltip>
    )
  }
}

Submit.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Submit)))
