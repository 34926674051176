import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Icon from '../Icon'

import {} from '../../actions'

const styles = theme => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 0,
  },
  label: {
    display: 'block'
  },
  labelLast: {
    fontWeight: 700,
    fontSize: '.85rem'
  },
  icon: {
    display: 'flex'
  }
})

class BreadcrumbElement extends React.Component {
  render() {
    const { classes, element, appConfig, isLast } = this.props

    let navElement = {}

    if (element.action) {
      navElement = {
        component: NavLink,
        to: appConfig.appPrefix + element.action
      }
    }

    return (
      <Button size='small' {...navElement} classes={{
        root: classes.button,
        label: classes.label + (isLast ? ' ' + classes.labelLast : '')
      }}>
        {element.icon ? (
          <Icon className={classes.icon}>{element.icon}</Icon>
        ) : element.text}
      </Button>
    )
  }
}

BreadcrumbElement.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(BreadcrumbElement)))
