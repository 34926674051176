import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import MUIButton from '@material-ui/core/Button'
import Zoom from '@material-ui/core/Zoom'
import Icon from '../Icon'

import {
  printActions,
  formActions,
  messageActions
} from '../../actions'

const styles = theme => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
    alignSelf: 'flex-start',
  },
  container: {
    flexGrow: 1
  },
  label: {
    padding: '0 .5rem'
  }
})

class Button extends React.Component {
  getFieldsAsParams = () => {
    const { formContent, field } = this.props

    const { fields } = formContent

    const { fieldParams = {} } = field

    const toReturn = {}

    Object.entries(fieldParams).forEach(([key, value]) => {
      const tmpField = fields.find(currentField => {
        return currentField.name === value
      })

      if (tmpField) {
        toReturn[key] = tmpField.value
      }
    })

    return toReturn
  }

  render() {
    const { classes, field, onChange, readonly, appConfig, match, history } = this.props

    const { action, actionType, saveBeforeAction, interpolateUrl, styles = {} } = field

    let actionProps = {
      onClick : () => onChange({type: 'string'}, field, '')
    }

    switch (actionType) {
      case 'simpleCall':
        actionProps = {
          onClick : () => this.props.onAjaxCall(field.url, match.params, data => {
            const { status, message } =  data

            if (status && message) {
              this.props.onShowMessage({
                message,
                type: status,
                autoHide: true
              })
            }
          })
        }

        break
      case 'download':
        actionProps = {
          href: field.url,
          target: '_blank',
          rel: 'noopener noreferrer',
          download: field.download
        }

        if (field.urlType === 'api') {
          actionProps.href = appConfig.apiUrl + actionProps.href
        }

        break
      case 'navlink':
        actionProps = {
          component: NavLink,
          to: appConfig.appPrefix + action
        }

        if (saveBeforeAction) {
          actionProps = {
            onClick : () => {
              return onChange({type: 'saveBeforeAction'}, {
                callback: (params) => {
                  history.push(appConfig.appPrefix + ((params || {}).interpolatedUrl || action))
                },
                callbackProps: [],
                callbackUrlInterpolation: interpolateUrl ? action : null,
                params: match.params
              }, '')
            }
          }
        }

        break
      case 'print':
        actionProps = {
          onClick : () => {
            if (saveBeforeAction) {
              return onChange({type: 'saveBeforeAction'}, {
                callback: this.props.onPrintPost,
                callbackProps: [action, this.getFieldsAsParams()],
                params: match.params
              }, '')
            }

            return this.props.onPrintPost(action, this.getFieldsAsParams())
          }
        }

        break
      default:
    }

    return (
      <Tooltip TransitionComponent={Zoom} title={field.tooltip || ''}>
        <div className={classes.container}>
          <MUIButton fullWidth={true} {...actionProps} variant='contained' color='primary' disabled={readonly} className={classes.root} style={styles} >{field.icon ? (
            <Icon className={field.label ? classes.icon : ''}>{field.icon}</Icon>
          ) : null} <span className={classes.label}>{field.label}</span></MUIButton>
        </div>
      </Tooltip>
    )
  }
}

Button.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer, ...state.formReducer })

const mapDispatchToProps = dispatch => ({
  onAjaxCall: (url, params, callback) => {
    dispatch(formActions.ajaxCall(url, params, callback))
  },
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  },
  onShowMessage: messageConfig => {
    dispatch(messageActions.showMessage(messageConfig))
  },
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Button)))
