import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MuiListItem from '@material-ui/core/ListItem'
import Tooltip from '@material-ui/core/Tooltip'
import IconSet from '../IconSet'

import {
  appActions
} from '../../actions'

const styles = theme => ({
  menuItemTitle: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    color: theme.palette.text.default,
    padding: '0 2rem 0 0',
    backgroundColor: 'rgba(0, 0, 0, .2)',
  },
  menuItem: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    color: theme.palette.text.default,
    padding: '0 2rem 0 0',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 10,
      left: 10,
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: theme.palette.warning.main,
      transform: 'scale(0)',
      transition: 'transform .35s ease-in-out'
    }
  },
  menuItemActive: {
    backgroundColor: theme.palette.primary.dark,
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: 3,
      backgroundColor: theme.palette.primary.light
    }
  },
  menuIcon: {
    fontSize: 18,
    padding: 15,
    minWidth: 48,
    maxWidth: 48,
    textAlign: 'center'
  },
  insetIcon: {
    display: 'block',
    color: 'rgba(255, 255, 255, .3)',
  },
  iconSet: {
    display: 'block',
    position: 'relative',
    width: 24,
    textAlign: 'center'
  },
  subIcon: {
    position: 'absolute',
    fontSize: 18,
    bottom: -3,
    right: -3,
    color: '#fff',
  },
  menuDivider: {
    height: 3,
    backgroundColor: 'rgba(0, 0, 0, .7)'
  },
  itemLabel: {
    fontSize: '.75rem'
  },
  expansionPanelRoot: {
    padding: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 0,
    '&::before': {
      content: 'none'
    }
  },
  expansionPanelExpanded: {
    margin: '0 !important'
  },
  expansionPanelSummaryRoot: {
    padding: 0,
    display: 'block'
  },
  expansionPanelSummaryContent: {
    padding: 0,
    margin: 0,
    border: 0,
  },
  hasBadge: {
    position: 'relative',
    '&::before': {
      transform: 'scale(1)'
    }
  },
  dBlock: {
    display: 'block'
  }
})

class ListItem extends React.Component {
  isActive = (match, location, isFirst, item) => {
    const { appConfig } = this.props

    if (isFirst && (location.pathname === '/' || location.pathname === appConfig.appPrefix || location.pathname === appConfig.appPrefix + '/')) {
      return true
    }

    let regex = new RegExp('^' + appConfig.appPrefix + (item.match || item.action), 'gi')

    return regex.test(location.pathname)
  }

  isSelectd = () => {
    const { item, sidebarIsOpen, sidebarSelectedElement } = this.props

    if (sidebarIsOpen && sidebarSelectedElement === item.index) {
      return true
    }

    return false
  }

  render() {
    const { classes, item, sidebarIsOpen, isFirst, appConfig, className, isActive, badges } = this.props

    let listItemConfig = {
      onClick: () => {
        if (!sidebarIsOpen) {
          this.props.onToggleSidebar()
        }

        return this.props.onToggleSidebarElement(item.index)
      }
    }

    if (!item.data && !item.external) {
      listItemConfig = {
        component: NavLink,
        to: appConfig.appPrefix + item.action,
        isActive: (match, location) => this.isActive(match, location, isFirst, item),
        activeClassName: classes.menuItemActive
      }
    }

    if (item.external) {
      listItemConfig = {
        component: 'a',
        href: item.action,
        target: '_blank'
      }
    }

    const listItemClasses = [classes.menuItem]

    if (item.badge && badges[item.badge]) {
      listItemClasses.push(classes.hasBadge)
    }

    if (className) {
      listItemClasses.push(className)
    }

    if (isActive) {
      listItemClasses.push(classes.menuItemActive)
    }

    const iconBlock = (
      <span className={classes.menuIcon}>
        <span className={classes.dBlock}>
          <IconSet iconSetClasses={{
            iconSet: classes.iconSet,
            icon: classes.Icon,
            inSetIcon: classes.insetIcon,
            subIcon: classes.subIcon
          }} icon={item.icon} far={item.far} />
        </span>
      </span>
    )

    return (
      <MuiListItem className={listItemClasses.join(' ')} button {...listItemConfig}>
        {sidebarIsOpen ? iconBlock : (
          <Tooltip title={item.label} placement='right'>
            {iconBlock}
          </Tooltip>
        )}
        <span className={classes.itemLabel}>{item.label}</span>
      </MuiListItem>
    )
  }
}

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer, ...state.sidebarReducer })

const mapDispatchToProps = dispatch => ({
  onToggleSidebarElement: (element) => {
    dispatch(appActions.toggleSidebarElement(element))
  },
  onToggleSidebar: () => {
    dispatch(appActions.toggleSidebar())
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ListItem)))
