import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { validators } from './Validators'

import {} from '../../actions'

const styles = theme => ({
  label: {
    color: theme.palette.common.black,
    fontWeight: 600,
    textTransform: 'uppercase',
    transform: 'translate(14px, 11px) !important',
    '&::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      zIndex: -1,
      marginTop: -3,
      right: 0,
      left: 0,
      height: 6,
      transition: 'left .3s ease-in-out, right .3s ease-in-out'
    }
  },
  input: {
    padding: '.75rem',
    backgroundColor: theme.palette.common.white
  },
  labelShrink: {
    transform: 'translate(14px, -7px) !important',
    '&::after': {
      right: -5,
      top: '50%',
    }
  },
  inputReadOnly: {
    backgroundColor: theme.palette.grey[50]
  },
  textField: {
    marginTop: 0,
  }
})

class Text extends React.Component {
  textRef = null

  state = {
    error: false,
    errorMessage: null,
    stringValue: '',
  }

  componentDidMount() {
    const { value } = this.props.field

    this.setState({
      stringValue: value || ''
    })

    return validators.mount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    const { stringValue } = this.state

    const { stringValue: preStringValue } = prevState

    const fromState = stringValue !== preStringValue

    const { field, onChange } = this.props

    let { value } = field

    value = value || ''

    if (!fromState && value !== stringValue) {
      this.setState({
        stringValue: value || ''
      }, () => {
        this.textRef.scrollTop = this.textRef.scrollHeight

        onChange({type: 'string'}, field, value)
      })
    }

    return validators.update(this, prevProps)
  }

  onChange = (event) => {
    const { field, onChange } = this.props

    const { value } = event.target

    this.setState({
      stringValue: value
    })

    return onChange({type: 'string'}, field, value)
  }

  render() {
    const { classes, field, readonly } = this.props

    const { rows = 5 } = field

    const { stringValue } = this.state

    const fieldReadonly = readonly || field.readonly

    return (
      <TextField
        id={field.name}
        name={field.name}
        label={field.label}
        className={classes.textField}
        InputProps={{
          classes: {
            root: classes.input + (fieldReadonly ? ' ' + classes.inputReadOnly : ''),
          },
          readOnly: fieldReadonly,
          inputProps: {
            ref: textRef => this.textRef = textRef
          }
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
            shrink: classes.labelShrink,
          }
        }}
        multiline={true}
        rows={rows}
        fullWidth
        variant='outlined'
        onChange={this.onChange}
        margin='normal'
        error={field.error ? true : false}
        helperText={field.error ? field.error : field.snippet || ''}
        value={stringValue} />
    )
  }
}

Text.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Text)))
