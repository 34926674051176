import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'

import {} from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    maxHeight: '90%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '&:focus': {
      outline: 'none'
    }
  },
  xl: {
    minWidth: '90%',
    maxWidth: '90%',
  },
  '@media (max-width: 800px)': {
    xl: {
      maxWidth: 'calc(100% - 40px)',
      minWidth: 'calc(100% - 40px)'
    }
  },
  md: {
    minWidth: 800,
    maxWidth: 800,
  },
  lg: {
    minWidth: 1000,
    maxWidth: 1000,
  },
  '@media (max-width: 900px)': {
    md: {
      maxWidth: 'calc(100% - 40px)',
      minWidth: 'calc(100% - 40px)'
    }
  },
  body: {
    padding: theme.spacing.unit * 4,
    flexGrow: 1,
    overflow: 'auto',
  },
  header: {
    padding: theme.spacing.unit * 4,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: theme.palette.grey[300]
    }
  },
  footer: {
    padding: theme.spacing.unit * 4,
    paddingTop: 0,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: theme.palette.grey[300]
    }
  },
  closeButton: {
    position: 'absolute',
    right: -20,
    top: -20,
    zIndex: 1
  }
})

class ModalComponent extends React.Component {
  state = {
    open: false
  }

  toggleModal = () => {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    const {
      classes,
      open,
      onClose,
      type,
      header,
      body,
      footer,
      modalClasses = {}
    } = this.props

    const rootClass = classes[type] || classes.xl

    return (
      <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={open || false} onClose={onClose} className={classes.root} >
        <div className={classes.paper + ' ' + rootClass}>
          <Fab size='small' className={classes.closeButton} onClick={onClose}>
            <Icon>close</Icon>
          </Fab>
          {header ? (
            <div className={[
              classes.header,
              modalClasses.body
            ].join(' ')}>
              {header}
            </div>
          ) : ''}
          <div className={[
            classes.body,
            modalClasses.body
          ].join(' ')}>
            {body}
          </div>
          {footer ? (
            <div className={[
              classes.footer,
              modalClasses.body
            ].join(' ')}>
              {footer}
            </div>
          ) : ''}
          <SimpleModalWrapped />
        </div>
      </Modal>
    )
  }
}

ModalComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

const SimpleModalWrapped = withStyles(styles, { withTheme: true })(ModalComponent)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleModalWrapped))
