import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Icon from '../Icon'
import Date from './Date'
import Checkbox from './Checkbox'
import moment from 'moment'
import _ from 'lodash'

import {
  printActions
} from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  checkbox: {
    marginRight: '1rem',
    marginTop: -13
  },
  checkboxLabel: {
    fontSize: '0.9285714285714286rem',
    fontWeight: 700,
    textTransform: 'uppercase',
    margin: '-.15rem 0 .125rem'
  },
  checkboxButtonContainer: {
    display: 'flex',
  },
  checkboxRoot: {
    margin: '0 0 0 -8px'
  },
  switchRoot: {
    height: 34
  },
  buttonIcon: {
    marginRight: '.5rem'
  },
  dateContainer: {
    flexGrow: 1,
  }
})

class Print extends React.Component {
  state = {}

  startPrint = () => {
    const { field, onChange, match } = this.props

    const { saveBeforeAction } = field

    const tmpValue = _.cloneDeep(field.value)

    const params = tmpValue.button.value.params || {}

    if (saveBeforeAction) {
      return onChange({ type: 'saveBeforeAction' }, {
        callback: this.props.onPrintPost,
        callbackProps: [tmpValue.button.value.url, {
          ...params,
          date: field.value.date.value
        }],
        params: match.params
      }, '')
    }

    return this.props.onPrintPost(tmpValue.button.value.url, {
      ...params,
      date: field.value.date.value
    })
  }

  onButtonClick = () => {
    const { field, onChange, appConfig } = this.props

    const { dateFormat } = appConfig

    const tmpValue = _.cloneDeep(field.value)

    tmpValue.date.value = field.value.date.value || moment().format(dateFormat)

    tmpValue.checkbox.value = true

    this.setState({
      clicked: true
    }, () => {
      onChange({ type: 'update' }, field, tmpValue)
    })
  }

  componentDidUpdate = (prevProps) => {
    const {
      clicked
    } = this.state

    const {
      date: prevDate = {},
      checkbox: prevCheckbox = {}
    } = prevProps.field.value

    const {
      date = {},
      checkbox = {}
    } = this.props.field.value

    const checked = (!prevCheckbox.value && checkbox.value) || checkbox.value

    const withDate = !(!((!prevDate.value && date.value) || date.value))

    if (checked && withDate && clicked) {
      return this.setState({
        clicked: false
      }, this.startPrint)
    }
  }

  render() {
    const { classes, field, onChange, readonly } = this.props

    const { checkbox, button, date } = field.value

    if (!checkbox) {
      return ''
    }

    return (
      <div className={classes.root}>
        <div className={classes.checkbox}>
          <div className={classes.checkboxLabel}>
            {checkbox.label}
          </div>
          <div className={classes.checkboxButtonContainer}>
            <div>
              <Checkbox field={{
                value: checkbox.value
              }} readonly={readonly} customClasses={{
                root: classes.checkboxRoot,
                switch: {
                  classes: {
                    root: classes.switchRoot,
                    //switchBase: classes.switchRoot,
                  }
                }
              }} onChange={(action, newField, value) => {
                return onChange({ type: 'update', index: null, property: 'checkbox' }, field, value)
              }} />
            </div>
            <Button disabled={readonly} variant='contained' color='primary' onClick={this.onButtonClick}>
              {button.icon ? (
                <Icon className={classes.buttonIcon}>{button.icon}</Icon>
              ) : ''}
              {button.label}
            </Button>
          </div>
        </div>
        <div className={classes.dateContainer}>
          <Date field={{
            label: date.label,
            value: date.value
          }} readonly={readonly} onChange={(action, newField, value) => {
            return onChange({ type: 'update', index: null, property: 'date' }, field, value)
          }} />
        </div>
      </div>
    )
  }
}

Print.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onPrintPost: (url, params) => {
    dispatch(printActions.printPost(url, params))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Print)))
