import axios from 'axios'
import store from '../stores'
import _, { cloneDeep } from 'lodash'

import {
  appActions,
  messageActions
} from '../actions'

const callsTail = []

let refreshingToken = false

const stopLoading = (activeLoading) => {
  if (activeLoading && !callsTail.length) {
    return setTimeout(() => {
      store.dispatch(appActions.stopLoading())
    }, 10)
  }

  if (callsTail.length) {
    return setTimeout(() => {
      stopLoading(activeLoading)
    }, 1000)
  }
}

const startLoading = () => {
  store.dispatch(appActions.startLoading())
}

export const isValidDate = (date) => {
  return window.appConfig.dateFormatPattern.test(date)
}

export const getPrevPage = (goToEdit, match) => {
  const currentPageArray = match.url.split('/')

  if (!goToEdit) {
    const removaLastNElements = isNaN(currentPageArray[currentPageArray.length - 1]) ? 1 : 2

    return currentPageArray.slice(0, currentPageArray.length - removaLastNElements).join('/')
  }

  const parentIndex = currentPageArray.findIndex(item => {
    return item === goToEdit.current
  })

  const toReturn = [
    ...currentPageArray.slice(0, parentIndex - 1),
    'edit',
    currentPageArray[parentIndex - 1]
  ]

  if (goToEdit.append) {
    if (goToEdit.appendIfNotParent && toReturn[toReturn.length - 3] !== goToEdit.appendIfNotParent) {
      toReturn.push(goToEdit.append)
    }
  }

  return toReturn.join('/')
}

export const getParsedLocation = (location) => {
  try {
    return location.forRelated
  } catch (e) {
    return {}
  }
}

export const ajaxCall = (obj) => {
  const sourceObj = cloneDeep(obj)

  const callId = _.uniqueId('call_')

  callsTail.push(callId)

  obj.config.headers = {
    Accept: 'application/json',
    authtoken: localStorage.getItem('token')
  }

  obj.config.timeout = obj.config.timeout || 300000

  axios.defaults.withCredentials = true

  const { appConfig } = store.getState().appReducer

  const activeLoading = obj.activeLoading

  if (activeLoading) {
    startLoading()
  }

  obj.config.url = appConfig.apiUrl + obj.config.url

  if (obj.config.url.includes('/:db/')) {
    obj.config.url = obj.config.url.replace('/:db/', '/' + appConfig.selectedDb.value + '/')
  }

  (obj.config.url.match(/\/:[a-zA-Z0-9]+/is) || []).forEach(item => {
    const param = item.replace('/:', '')

    obj.config.url = obj.config.url.replace(item, '/' + obj.config.params[param])
  })

  obj.config.params = {
    ...obj.config.params,
    v: new Date().getTime()
  }

  setTimeout(() => {
    axios(obj.config).then(res => {
      if (res.headers.authtoken && !obj.silently && !obj.preventToken && localStorage.getItem('token')) {
        localStorage.setItem('token', res.headers.authtoken)
      }

      callsTail.splice(callsTail.indexOf(callId), 1)

      stopLoading(activeLoading)

      if (res.data.modalMessage) {
        store.dispatch(messageActions.showMessage({
          ...res.data.modalMessage,
          messageType: 'modal',
          autoHide: false
        }))
      }

      if (res.data.systemMessages) {
        Object.entries(res.data.systemMessages).forEach(([key, value]) => {
          const systemMessage = {
            ...value,
            messageId: key,
            autoHide: false
          }

          if (!obj.silently) {
            store.dispatch(messageActions.showMessage(systemMessage))
          }
        })
      }

      if (res.data.status === 'error' && res.data.message) {
        store.dispatch(messageActions.showMessage({
          message: res.data.message,
          type: 'error',
          autoHide: true
        }))
      }

      return obj.success(res)
    }).catch(error => {
      const { data: errorData = {}, status } = error.response || {}

      callsTail.splice(callsTail.indexOf(callId), 1)

      stopLoading(activeLoading)

      switch (errorData.errorType) {
        case 'auth':
        case 'tokenExpired':
          localStorage.removeItem('token')

          break
        default:
          break
      }

      if (status === 401) {
        return setTimeout(() => {
          store.dispatch(appActions.initApp())

          return obj.error(error)
        }, 10)
      }

      let errorMessage = {
        message: errorData.message || error.message,
        type: 'error',
        autoHide: true
      }

      if (!obj.silently) {
        store.dispatch(messageActions.showMessage(errorMessage))
      }

      if (typeof obj.error === 'function') {
        return obj.error(error)
      }
    })
  }, 10)
}

export const stripeHtml = html => {
  return html.replace(/(<([^>]+)>)/g, '').replace(/\s+/g, ' ').trim()
}

export { handlebarsRender } from './handlebars'

export const clsx = classes => {
  return classes.filter(item => !(!item)).join(' ')
}
