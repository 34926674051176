import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import {
} from '../../actions'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    minHeight: 500
  },
  rootTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    padding: '0 0 1rem'
  },
  rootContent: {
    paddingBottom: theme.spacing.unit,
    maxHeight: 500,
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap'
  },
  item: {
    width: '50%',
    '&:nth-child(odd)': {
      borderRight: '1px solid ' + theme.palette.grey[300],
    }
  },
  fullWidthItem: {
    width: '100%'
  },
  itemTitle: {
    fontSize: '1.2rem',
    fontWeight: 500,
  }
})

class ChartGroup extends React.Component {
  render() {
    const { classes, item } = this.props

    return (
      <Paper className={classes.root}>
        {item.title ? (
          <div className={classes.rootTitle}>
            {item.title}
          </div>
        ) : ''}
        <div className={classes.rootContent}>
          {item.items.map((innerItem, innerIndex) => {
            let options = null

            switch (innerItem.type) {
              case 'pie':
                options = {
                  chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                  },
                  credits: {
                    enabled: false
                  },
                  title: {
                    text: innerItem.title
                  },
                  tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                  },
                  accessibility: {
                    point: {
                      valueSuffix: '%'
                    }
                  },
                  plotOptions: {
                    pie: {
                      allowPointSelect: true,
                      cursor: 'pointer',
                      dataLabels: {
                        enabled: true,
                        format: '<strong>{point.name}</strong>: {point.y}',
                      },
                      showInLegend: false
                    }
                  },
                  series: innerItem.series
                }

                break
              case 'column':
                options = {
                  chart: {
                    type: 'column'
                  },
                  credits: {
                    enabled: false
                  },
                  title: {
                    text: innerItem.title
                  },
                  xAxis: innerItem.xAxis,
                  yAxis: {
                    min: 0,
                    stackLabels: {
                      enabled: true,
                      style: {
                        fontWeight: 'bold',
                        color: ( // theme
                          Highcharts.defaultOptions.title.style &&
                          Highcharts.defaultOptions.title.style.color
                        ) || 'gray'
                      }
                    },
                    ...innerItem.xAxis
                  },
                  legend: {
                    backgroundColor:
                      Highcharts.defaultOptions.legend.backgroundColor || 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false
                  },
                  tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                  },
                  plotOptions: {
                    column: {
                      stacking: 'normal',
                      dataLabels: {
                        enabled: true
                      }
                    }
                  },
                  series: innerItem.series
                }

                break
              default:
                break
            }

            return (
              <div className={item.size === 12 ? classes.item : classes.fullWidthItem} key={innerIndex}>
                <div className={classes.itemContent}>
                  <HighchartsReact
                    containerProps={{ className: classes.itemContentChartRoot }}
                    highcharts={Highcharts}
                    options={options} />
                </div>
              </div>
            )
          })}
        </div>
      </Paper>
    )
  }
}

ChartGroup.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ChartGroup)))
