import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { validators } from './Validators'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import Icon from '../Icon'

import {} from '../../actions'

const styles = theme => {
  return {helperText: {
    position: 'absolute',
    top: '100%',
    marginTop: '2px',
  },
  input: {
    padding: '.75rem .625rem',
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  inputDisabled: {
    opacity: '.8',
    backgroundColor: theme.palette.grey[50],
    fontWeight: 600,
    color: theme.palette.grey['A400']
  },
  label: {
    color: theme.palette.common.black,
    fontWeight: 600,
    textTransform: 'uppercase',
    transform: 'translate(14px, 11px) !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100% - 2rem)',
    '&::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      zIndex: -1,
      marginTop: -3,
      right: 0,
      left: 0,
      height: 6,
      transition: 'left .3s ease-in-out, right .3s ease-in-out'
    }
  },
  labelShrink: {
    transform: 'translate(14px, -7px) !important',
    '&::after': {
      right: -5,
      top: '50%',
    }
  },
  inputReadOnly: {
    backgroundColor: theme.palette.grey[50]
  },
  textField: {
    marginTop: 0,
    marginBottom: 0,
  },
  clearIcon: {
    cursor: 'pointer',
    '& .material-icons': {
      fontSize: 18
    }
  }}
}

class String extends React.Component {
  state = {
    stringValue: '',
    inputType: 'text'
  }

  componentDidMount() {
    const { value, inputType } = this.props.field

    this.setState({
      stringValue: value === 0 ? '' + value : (value || ''),
      inputType: inputType || 'text'
    })

    return validators.mount(this)
  }

  componentDidUpdate(prevProps, prevState) {
    const { stringValue } = this.state

    const { stringValue: preStringValue } = prevState

    const fromState = stringValue !== preStringValue

    let { value: prevValue } = prevProps.field

    prevValue = prevValue === 0 ? '' + prevValue : (prevValue || '')

    const { field, onChange } = this.props

    let { value } = field

    value = value === 0 ? '' + value : (value || '')

    if (!fromState && (value !== prevValue || value !== stringValue)) {
      this.setState({
        stringValue: value
      }, () => {
        onChange({type: 'string'}, field, value)
      })
    }

    return validators.update(this, prevProps)
  }

  onChange = (event) => {
    const { field, onChange } = this.props

    const { value } = event.target

    this.setState({
      stringValue: value
    })

    return onChange({type: 'string'}, field, value)
  }

  render() {
    const { classes, field, className, readonly, autotooltip } = this.props

    const { disabled, placeholder, min, max, step } = field

    const { stringValue, inputType } = this.state

    const startAdornment = field.icon && field.icon.start ? (
      <InputAdornment position='start'>
        <Icon>{field.icon.start}</Icon>
      </InputAdornment>
    ) : null

    let endAdornment = field.icon && field.icon.end ? (
      <InputAdornment position='end'>
        <Icon>{field.icon.end}</Icon>
      </InputAdornment>
    ) : null

    if (field.inputType === 'password') {
      endAdornment = (
        <InputAdornment position='end' onClick={() => {
          return this.setState({
            inputType: inputType === 'text' ? 'password' : 'text'
          })
        }} >
          <Icon>{inputType === 'text' ? 'eye' : 'eye-slash'}</Icon>
        </InputAdornment>
      )
    }

    if (field.clearable && this.state.stringValue) {
      endAdornment = (
        <InputAdornment position='end'
          onClick={() => {
            this.props.onChange(null, null, '')

            return this.setState({
              stringValue: ''
            })
          }} >
          <Icon className={classes.clearIcon}>close</Icon>
        </InputAdornment>
      )
    }

    const fieldReadonly = readonly || field.readonly

    const fieldAutotooltip = fieldReadonly || autotooltip

    const autoComplete = field.inputType === 'password' ? {autoComplete: 'new-password'} : {}

    return (
      <Tooltip TransitionComponent={Zoom} title={fieldAutotooltip ? stringValue : ''}>
        <TextField
          id={field.name + '-' + field.valueIndex}
          name={field.name}
          label={field.label}
          type={inputType}
          placeholder={placeholder}
          className={(className ? className + ' ' : '') + classes.textField}
          InputProps={{
            classes: {
              input: classes.input + (fieldReadonly ? ' ' + classes.inputReadOnly : ''),
              disabled: classes.inputDisabled
            },
            endAdornment,
            startAdornment,
            inputProps: {
              ...autoComplete,
              readOnly: fieldReadonly,
              min,
              max,
              step
            }
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              shrink: classes.labelShrink,
            }
          }}
          FormHelperTextProps={{
            className: classes.helperText
          }}
          disabled={disabled}
          fullWidth
          value={stringValue}
          variant='outlined'
          onChange={this.onChange}
          margin='normal'
          error={field.error ? true : false}
          helperText={field.error} />
      </Tooltip>
    )
  }
}

String.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(String)))
