import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Paper from '@material-ui/core/Paper'
import Icon from '../../../Icon'

import {
} from '../../../../actions'

const styles = theme => ({
  root: {
    margin: '0 -.5rem',
    padding: '.5rem',
    overflowY: 'auto',
    flex: '0 1 auto'
  },
  paper: {
    padding: '.5rem',
    marginBottom: '1rem',
    '&:last-child': {
      marginBottom: 0
    }
  },
  tableCell: {
    padding: '.5rem 0'
  },
  tableRow: {
    height: 'auto'
  },
  table: {
    overflow: 'hidden',
    height: 80,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      height: 10,
      background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)'
    }
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.5rem'
  }
})

class Timetable extends React.Component {
  state = {}

  render() {
    const { workers, classes } = this.props

    const { open } = this.state

    if (!workers) {
      return ''
    }

    return (
      <div className={classes.root}>
        {workers.map((worker, index) => {
          if (!worker.timetable) {
            return ''
          }

          return (
            <Paper className={classes.paper} key={index}>
              <div className={classes.title}>
                {[worker.name, worker.cf].filter(item => !(!item)).join(' - ')}
              </div>
              <div className={worker.timetable.body.length > 2 && open !== index ? classes.table : ''}>
                <Table>
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      {worker.timetable.header.map((headerItem, headerIndex) => {
                        return (
                          <TableCell key={headerIndex} className={classes.tableCell}>
                            {headerItem}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {worker.timetable.body.map((bodyItem, bodyIndex) => {
                      return (
                        <TableRow key={bodyIndex} className={classes.tableRow}>
                          {worker.timetable.header.map((headerItem, headerIndex) => {
                            return (
                              <TableCell key={headerIndex} className={classes.tableCell}>
                                {bodyItem[headerIndex]}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
              {worker.timetable.body.length > 2 ? (
                <div className={classes.action} onClick={() => {
                  this.setState({
                    open: open === index ? null : index
                  })
                }}>
                  <Icon>{'angle-double-' + (!open ? 'down' : 'up')}</Icon>
                </div>
              ) : ''}
            </Paper>
          )
        })}
      </div>
    )
  }
}

Timetable.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Timetable)))
